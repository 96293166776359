import Cookies from 'js-cookie';

const played = Cookies.get('played') || '0';
if (played === '1') {
  const body = document.getElementsByTagName("html")[0];
  body.classList.add('played');
}

function play_topvideo(onended) {
    var vid = document.getElementById("home-video");
    try {
        vid.play().catch(function() { });
    } catch (e) {}

    vid.controls = false;
    vid.onended = onended;
}

document.addEventListener('DOMContentLoaded', function() {
  const body = document.getElementsByTagName("body")[0];
  body.classList.add('loaded');
  // Wait for transition also, since transion only .5s
  const onfinish = () => {
    // const body = document.getElementsByTagName("body")[0];
    body.classList.add('fullloaded');
    Cookies.set('played', '1', { expires: 7, path: '', sameSite: 'strict', secure: location.protocol === 'https:'})
  };

  // Video play
  if (jQuery('html.loading-video')) {
    play_topvideo(onfinish);
  }
  else {
    // Length of video is 6 seconds
    setTimeout(onfinish, 6000);
  }
});
